import request from '@/utils/request'
import DefaultModel from '@/models/reclamaciones'
import { format } from 'date-fns'
import { complaintsFilterPayload } from '@/utils/genericUtils'

import axios from 'axios'

const state = {
    lista: [],
    listBussines: [],
    listPremises: [],
    listaEstadoReclamaciones: [],
    datos: new DefaultModel.BaseForm(),
    itemsDocumento: [
        { value: 1, label: 'DNI' },
        { value: 2, label: 'CARNET DE EXTRANJERIA' },
        { value: 3, label: 'RUC' },
        { value: 4, label: 'PASAPORTE' }
    ],
    tipoBien: [
        { value: 1, label: 'PRODUCTO' },
        { value: 2, label: 'SERVICIO' }
    ],
    tipoReclamo: [
        { value: 1, label: 'RECLAMO' },
        { value: 2, label: 'QUEJA' }
    ],
    tipoNotificacion: [
        { value: 1, label: 'DOMICILIO' },
        { value: 2, label: 'EMAIL' }
    ],
    tipoDetalleReclamo: [
        { value: 1, label: 'PRODUCTO' },
        { value: 2, label: 'SERVICIO' },
        { value: 3, label: 'OTRO' }
    ]
}
const getters = {
    lista: (_state) => (_state.lista.length > 0 ? _state.lista : []),
    listaEstadoReclamaciones: (_state) => {
        const items = _state.listaEstadoReclamaciones
        if (items) {
            return items.sort((a, b) => b.id - a.id)
        } else {
            return []
        }
    }
}

const mutations = {
    SET_LISTA: (state, payload) => {
        state.lista = payload
    },
    SET_LISTA_BUSSINES: (state, payload) => {
        state.listBussines = payload
    },
    SET_LISTA_PREMISES: (state, payload) => {
        state.listPremises = payload
    },
    RESET_FORM: (state, payload) => {
        state.datos = new DefaultModel.BaseForm()
    },
    UPDATE_DATOS: (state, payload) => {
        state.datos = payload
    },
    SET_LISTA_ESTADOS: (state, payload) => {
        state.listaEstadoReclamaciones = payload
    }
}

const actions = {
    async obtenerLista({ commit }, payload) {
        console.log('payload', payload)
        const { data } = await axios({
            url: '/ms/servicios/cm/v2.0/complaints/obtener',
            method: 'POST',
            data: complaintsFilterPayload(payload)
        })

        console.log('obtenerLista', data)
        commit('SET_LISTA', data)
    },
    async bussines({ commit }, payload) {
        const { data } = await axios({
            url: `/ms/servicios/cm/v2.0/bussines`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        commit('SET_LISTA_BUSSINES', data)
    },
    async premises({ commit }, payload) {
        const { data } = await axios({
            url: `/ms/servicios/cm/v2.0/premises`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        commit('SET_LISTA_PREMISES', data)
    },
    async getEstado({ commit, state }, payload) {
        console.log('payload_getEstado', payload)
        const { data } = await request({
            url: `/ms/servicios/cm/v2.0/complaints/history?id=${payload.id}`,
            method: 'GET'
        })
        console.log('getEstado', data)
        commit('SET_LISTA_ESTADOS', data)
    },

    async registrarEstadoReclamaciones({ commit }, payload) {
        console.log('post_payload ', payload)
        const { data } = await request({
            url: `/ms/servicios/cm/v2.0/complaints/estados`,
            method: 'POST',
            data: payload
        })
        console.log('registrarEstadoProspecto', data)
    },
    async actualizar({ commit, rootState }, payload) {
        console.log('post_payload ', payload)
        const datos = rootState.usuarios.userLogin
        console.log('datos', datos)
        payload.usuarioModificacion = datos.usuario
        const { data } = await request({
            url: `/ms/servicios/cm/v2.0/complaints`,
            method: 'PUT',
            data: payload
        })
        console.log('data', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
